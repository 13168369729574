import { navigation } from '@components/config/Footer'
import Image from 'next/image'
import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { company } from '@components/config/company/Details';
import Link from 'next/link';
import TwitterIcon from '@mui/icons-material/Twitter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { SocialLinks } from '@components/config/SocialLinks';

function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className='bg-black py-10 '>
            <div className='container md:px-0 px-10'>
                <div className=' grid md:grid-cols-3 gap-10'>
                    <div>
                        <Image src={company.logo_url} alt={company.logo_alt} width={company.width} height={company.height} />
                        <div className='text-white mt-5'>
                            We offer a full range of bus and coach<br />
                            hire with driver services.
                        </div>
                    </div>
                    <div>
                        <div className='text-white text-lg font-bold mb-5 bg-gradient-to-r from-blue-400 to-blue-900 inline-flex pb-1'>
                            <div className='bg-black '>Navigation</div>
                        </div>
                        <div>
                            {
                                navigation.navigation.map((item, index) => {
                                    return (
                                        <div key={index} className='my-4'>
                                            <Link href={item.href}>
                                                <a className='text-white hover:text-blue-600   transition-all'>
                                                    {item.name}
                                                </a>
                                            </Link>
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <div className='text-white text-lg font-bold mb-5 bg-gradient-to-r from-blue-400 to-blue-900 inline-flex pb-1'>
                            <div className='bg-black'>Quick Links</div>
                        </div>
                        <div>
                            {
                                navigation.navigation.map((item, index) => {
                                    return (
                                        <div key={index} className='my-4'>
                                            <Link href={item.href}>
                                                <a className='text-white hover:text-blue-600   transition-all'>
                                                    {item.name}
                                                </a>
                                            </Link>
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>
                    </div>

                </div>
                <div className=' mt-5 grid md:grid-cols-3 gap-10 border-b border-white/20 pb-5'>
                    <div className='flex gap-2 items-center'>
                        <div>
                            <LocationOnIcon className='text-blue-600 text-4xl' />
                        </div>
                        <div className='text-white'>
                            {company.address.building} {company.address.street}, {company.address.town}, {company.address.postcode}
                        </div>
                    </div>
                    <div >
                        <Link href={`tel:${company.tel_number}`}>
                            <a className='flex gap-2 items-center'>
                                <div>
                                    <LocalPhoneIcon className='text-blue-600 text-4xl' />
                                </div>
                                <div className='text-white'>
                                    {company.tel_number}
                                </div>
                            </a>
                        </Link>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <div>
                            <EmailIcon className='text-blue-600 text-4xl' />
                        </div>
                        <div className='text-white'>
                            {company.company_email}
                        </div>
                    </div>
                    {/* <div className='flex gap-2 items-center'>
                        {
                            SocialLinks.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <Link href={item.href}>
                                            <a className='text-white hover:text-blue-600 transition-all' aria-label={item.name}>
                                                {item.icon}
                                            </a>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div> */}

                </div>
                <div>
                    <div className='text-white/70 text-center mt-4 text-sm'>{company.Copyright}</div>
                </div>
                <div className='flex items-center justify-center text-white/70 flex-col'>
                    <div>
                        Website Developed By
                    </div>
                    <div className='rounded-xl mt-2'>
                        <Link href={'https://xiots.io/'}>
                            <a target={'_blank'}>
                                <Image src='/assets/images/Xiotslogo.webp' className='rounded-xl' alt='logo' width={70} height={30} />
                            </a>

                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer