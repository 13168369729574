export const HeaderTitles = [
    {
        name: 'Home',
        href: '/'

    },
    {
        name: 'About Us',
        href: '/about-us'
    },

    {
        name: 'Services',
        href: '/services'
    },
    {
        name: 'Contact Us',
        href: '/contact-us'
    }
]