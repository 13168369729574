import { company } from '@components/config/company/Details';
import { HeaderTitles } from '@components/config/HeaderTitles';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useRouter } from 'next/router';

function Header() {
  const router = useRouter();
  const path = router.pathname;
  const [drawerState, setDrawerState] = useState(false);
  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', (event) => {
      if (window.scrollY >= 150) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
  }, []);
  return (
    <div
      className={`md:px-0 px-10 py-2 ${
        sticky ? 'bg-transparent fixed' : 'bg-transparent absolute'
      } w-full z-50`}
    >
      <div
        className={`bg-primary rounded-3xl container  flex items-center justify-between md:px-10 px-4 md:py-2 py-2`}
      >
        <Link href={'/'}>
          <a>
            <Image
              src={company.logo_url}
              alt={company.logo_alt}
              width={company.width}
              height={company.height}
            />
          </a>
        </Link>
        <div className="flex items-center gap-5 ">
          <div className="md:flex hidden items-center justify-between gap-10 pr-5  py-8  text-white hover:text-white/50 transition-all">
            {HeaderTitles.map((item, index) => {
              return (
                <div key={index}>
                  <div>
                    <Link href={item.href}>
                      <a
                        className={`hover:text-white transition-all border-b-4 rounded pb-2  text-lg font-semibold ${
                          path === item.href
                            ? 'border-blue-600'
                            : 'border-transparent'
                        }`}
                      >
                        {item.name}
                      </a>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="md:grid hidden grid-cols-1 gap-4 items-center">
            <Link href={`tel:${company.tel_number}`}>
              <a className="text-lg font-semibold text-white hover:underline">
                <div className="flex items-center gap-3">
                  <LocalPhoneIcon className="text-white text-2xl mr-2" />
                  <div>
                    <div>Call Center</div>
                    <div className="text-white/60 font-normal">
                      {company.tel_number}
                    </div>
                  </div>
                </div>
              </a>
            </Link>
          </div>

          <div
            onClick={() => {
              setDrawerState(!drawerState);
            }}
            className="md:hidden block bg-primary py-5 "
          >
            <MenuIcon className="text-white text-4xl" />
          </div>
        </div>
        <Drawer anchor="right" open={drawerState} onClose={toggleDrawer}>
          <div className="bg-black h-full overflow-scroll w-[80vw] md:w-[50vw] px-5 md:px-20 py-10">
            <div className="">
              <Link href={'/'}>
                <a>
                  <Image
                    src={company.logo_url}
                    alt={company.alt}
                    width={company.width}
                    height={company.height}
                  />
                </a>
              </Link>
              <CancelIcon
                className="absolute top-5 right-5 text-white cursor-pointer"
                onClick={toggleDrawer}
              />
            </div>

            <div className="mt-10 space-y-7">
              {HeaderTitles.map((item, index) => {
                return (
                  <div key={index}>
                    <div>
                      <Link href={item.href}>
                        <a
                          onClick={() => {
                            setDrawerState(false);
                          }}
                          className="text-white transition-all text-lg font-semibold font-mono"
                        >
                          {item.name}
                        </a>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default Header;
