export const navigation = {
  navigation: [
    {
      name: 'Home',
      href: '/',

    },
    {
      name: 'About Us',
      href: '/about-us',
    },
    {
      name: 'Services',
      href: '/services',
    },

  ],
  quickLinks: [
    {
      name: 'Contact Us',
      href: '/contact-us',
    },
    {
      name: "FAQ's",
      href: '/services/#faqs',

    }
  ],
}
